<script>
export default {
  name: 'pagination-ui',
  data() {
    return {
      active: 0
    }
  },
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
  },
  offset: 0,
  emits: [
    "changePage",
    "firstPage",
    "lastPage"
  ],
  methods:{
    activate:function(el){
      this.active = el;
    }
  },
  computed: {
    offset() {
      return (this.totalPages >= this.page) ? 0 : this.page - this.totalPages
    }
  }
}
</script>

<template>
  <a
      href="#"
      class="pagin__prev"
      @click="$emit('firstPage')"
  ></a>
  <ul
      v-for="pageNumber in totalPages"
      :key="pageNumber"
      class="pagin__list"
      @click="$emit('changePage', (pageNumber + offset)), activate(pageNumber)"
  >
    <li :class="{ active : active === pageNumber }"><a href="#">{{ pageNumber + offset }}</a></li>
  </ul>
  <a
      href="#"
      class="pagin__next"
      @click="$emit('lastPage')"
  ></a>
</template>

<style scoped>

</style>